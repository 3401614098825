/*

  Theme: Law theme

  -----------------------------------
  🚫 DO NOT EDIT THIS FILE DIRECTLY 🚫
  -----------------------------------

  To generate this file:
  1 - Edit `src/themes/law-theme` files
  2 - Run `yarn build:themes`

*/

[data-theme="law-theme"] {
	/* brandColors */
	--brand-1: #ae3c7c;
	/* background */
	--bg-4: #ae3c7cff;
	/* backgroundButton */
	--bg-button-1: #ae3c7cff;
	--bg-button-1-active: #ae3c7cff;
	--bg-button-1-focus: #ae3c7cff;
	/* textLink */
	--text-link-1: #ae3c7cff;
	--text-link-1-active: #ae3c7cff;
	--text-link-1-focus: #ae3c7cff;
	/* icon */
	--icon-1: #06021fff;
	--icon-2: #1a1f6cff;
	--icon-3: #ae3c7cff;
	--icon-disabled: #aaaaaaff;
	/* border */
	--border-4: #ae3c7cff;
}
[data-theme="law-theme"][data-subtheme="default"],
[data-theme="law-theme"] [data-subtheme="default"] {
	/* brandColors */
	--brand-1: #ae3c7c;
	/* background */
	--bg-4: #ae3c7cff;
	/* backgroundButton */
	--bg-button-1: #ae3c7cff;
	--bg-button-1-active: #ae3c7cff;
	--bg-button-1-focus: #ae3c7cff;
	/* textLink */
	--text-link-1: #ae3c7cff;
	--text-link-1-active: #ae3c7cff;
	--text-link-1-focus: #ae3c7cff;
	/* icon */
	--icon-1: #06021fff;
	--icon-2: #1a1f6cff;
	--icon-3: #ae3c7cff;
	--icon-disabled: #aaaaaaff;
	/* border */
	--border-4: #ae3c7cff;
}
[data-theme="law-theme"][data-subtheme="default-alt"],
[data-theme="law-theme"] [data-subtheme="default-alt"] {
	/* brandColors */
	--brand-1: #ae3c7c;
	/* background */
	--bg-4: #ae3c7cff;
	/* backgroundButton */
	--bg-button-1: #ae3c7cff;
	--bg-button-1-active: #ae3c7cff;
	--bg-button-1-focus: #ae3c7cff;
	/* textLink */
	--text-link-1: #ae3c7cff;
	--text-link-1-active: #ae3c7cff;
	--text-link-1-focus: #ae3c7cff;
	/* icon */
	--icon-1: #06021fff;
	--icon-2: #1a1f6cff;
	--icon-3: #ae3c7cff;
	--icon-disabled: #aaaaaaff;
	/* border */
	--border-4: #ae3c7cff;
	/* background */
	--bg-1: #efede8;
	--bg-5: #ffffff;
}
[data-theme="law-theme"][data-subtheme="inverse"],
[data-theme="law-theme"] [data-subtheme="inverse"] {
	/* brandColors */
	--brand-1: #ae3c7c;
	/* background */
	--bg-4: #ae3c7cff;
	/* backgroundButton */
	--bg-button-1: #ae3c7cff;
	--bg-button-1-active: #ae3c7cff;
	--bg-button-1-focus: #ae3c7cff;
	/* textLink */
	--text-link-1: #ae3c7cff;
	--text-link-1-active: #ae3c7cff;
	--text-link-1-focus: #ae3c7cff;
	/* icon */
	--icon-1: #06021fff;
	--icon-2: #1a1f6cff;
	--icon-3: #ae3c7cff;
	--icon-disabled: #aaaaaaff;
	/* border */
	--border-4: #ae3c7cff;
	/* background */
	--bg-ui: #06021fff;
	--bg-1: #1a1f6cff;
	--bg-2: #9b9fe1ff;
	--bg-3: #9b9fe1ff;
	--bg-4: #ae3c7c;
	--bg-5: #5055a7ff;
	--bg-disabled: #5055a7ff;
	/* backgroundButton */
	--bg-button-1-disabled: #515c6cff;
	--bg-button-2: #d6d1c4ff;
	--bg-button-2-hover: #f0ebddff;
	--bg-button-2-active: #d6d1c4ff;
	--bg-button-2-focus: #d6d1c4ff;
	--bg-button-2-disabled: #515c6cff;
	--bg-button-3: #f0ebdd00;
	--bg-button-3-hover: #f0ebdd1a;
	--bg-button-3-active: #f0ebdd00;
	--bg-button-3-focus: #f0ebdd00;
	--bg-button-3-disabled: #717c8c00;
	--bg-link-button-4: #d9d9d900;
	--bg-link-button-4-hover: #f0ebdd1a;
	--bg-link-button-4-active: #d9d9d900;
	--bg-link-button-4-focus: #d9d9d900;
	--bg-link-button-4-disabled: #515c6c00;
	/* borderButton */
	--border-button-1-focus: #4650f3ff;
	--border-button-1-disabled: #515c6c00;
	--border-button-2: #d6d1c400;
	--border-button-2-hover: #d6d1c400;
	--border-button-2-active: #d6d1c400;
	--border-button-2-focus: #4650f3ff;
	--border-button-2-disabled: #515c6c00;
	--border-button-3: #d6d1c4ff;
	--border-button-3-hover: #d6d1c4ff;
	--border-button-3-active: #d6d1c4ff;
	--border-button-3-focus: #4650f3ff;
	--border-button-3-disabled: #515c6cff;
	--border-button-4: #d9d9d900;
	--border-button-4-hover: #d9d9d900;
	--border-button-4-active: #d9d9d900;
	--border-button-4-focus: #4650f3ff;
	--border-button-4-disabled: #515c6c00;
	/* textButton */
	--text-button-1-disabled: #878d96ff;
	--text-button-2: #1a1f6cff;
	--text-button-2-hover: #5f639cff;
	--text-button-2-active: #1a1f6cff;
	--text-button-2-focus: #1a1f6cff;
	--text-button-2-disabled: #878d96ff;
	--text-button-3: #d6d1c4ff;
	--text-button-3-hover: #d6d1c4ff;
	--text-button-3-active: #d6d1c4ff;
	--text-button-3-focus: #d6d1c4ff;
	--text-button-3-disabled: #515c6cff;
	--text-button-4: #d6d1c4ff;
	--text-button-4-hover: #d6d1c4ff;
	--text-button-4-active: #d6d1c4ff;
	--text-button-4-focus: #d6d1c4ff;
	--text-button-4-disabled: #878d96ff;
	--text-button-5: #ff5700;
	--text-button-5-hover: #f89d4f;
	--text-button-5-active: #ff5700;
	--text-button-5-focus: #ff5700;
	--text-button-5-disabled: #b4b4b4;
	/* textLink */
	--text-link-1: #d6d1c4ff;
	--text-link-1-hover: #f0ebddff;
	--text-link-1-active: #d6d1c4ff;
	--text-link-1-focus: #d6d1c4ff;
	--text-link-1-disabled: #838e9cff;
	--text-link-menu: #d6d1c4ff;
	--text-link-menu-hover: #d6d1c4ff;
	--text-link-menu-active: #d6d1c4ff;
	--text-link-menu-focus: #d6d1c4ff;
	--text-link-menu-disabled: #838e9cff;
	/* text */
	--text-1: #ffffffff;
	--text-2: #d6d1c4ff;
	--text-3: #efede8ff;
	--text-4: #000000;
	--text-disabled: #7a8493ff;
	/* icon */
	--icon-1: #ffffff;
	--icon-2: #d6d1c4;
	--icon-3: #ae3c7c;
	--icon-disabled: #838e9c;
	/* border */
	--border-1: #1a1f6cff;
	--border-2: #464985ff;
	--border-3: #ffffffff;
	--border-disabled: #515c6cff;
	/* veilColors */
	--overlay-1: #00000054;
	--overlay-2: #000000cc;
	--overlay-3: #ffffff54;
	--overlay-4: #ffffffcc;
	/* support */
	--success: #5efb9dff;
	--focus-1: #4650f3ff;
	--focus-2: #3ee8ffff;
}
