.form {
	display: grid;
	gap: var(--spc-2xs);
}

.form.sending {
	position: relative;
}

.wrapper {
	gap: var(--spc-3xs);
	min-height: 40px;
	position: relative;
}

.wrapper.has_label {
	min-height: 60px;
	justify-content: flex-end;
}

.wrapper--input {
	gap: var(--spc-3xs);
	position: relative;
}

.icon,
.label {
	position: absolute;
	top: 19px;
	transform: translateY(-50%);
}

.icon--prepend {
	left: calc(var(--spc-3xs) + 2px);
}

.icon--append_inner {
	right: var(--spc-3xs);
}

.icon--append_inner.right_88 {
	right: calc(var(--spc-3xs) + 80px);
}

.icon.disabled > path {
	fill: var(--icon-disabled);
}

.icon--chevron {
	position: absolute;
	right: var(--spc-3xs);
	top: var(--spc-3xs);
	transition: .3s all ease;
}

.icon--chevron.icon--chevron__rotate {
	top: 10px;
	transform: rotate(180deg);
}

.label {
	left: calc(var(--spc-3xs) + 26px);
	overflow: hidden;
	color: var(--text-3);
	text-overflow: ellipsis;
	white-space: nowrap;
	transition: 0.2s top ease, 0.2s left ease;
}

.input {
	padding: var(--spc-3xs);
	height: auto;
	min-height: 38px;
	width: 100%;
	padding-left: calc(var(--spc-3xs) + 26px);
	background-color: var(--white);
	border: 1px solid transparent;
	line-height: 22px;
}

.input--checkbox {
	line-height: 0;
	min-height: 16px;
}

.input::placeholder {
	transition: 0.2s all ease;
}

.input:not(.active)::placeholder {
	opacity: 0;
	visibility: hidden;
	color: transparent;
}

.input.active ~ .label,
.containerInput.active ~ .label {
	top: -12px;
	left: 0;
	color: var(--text-1);
}

.input:hover,
.input:active,
.input:focus {
	outline: none !important;
}

.containerInput .input::placeholder {
	opacity: .5;
	visibility: visible;
	color: initial;
}

.input:hover {
	border-color: var(--border-2);
}

.input:focus {
	border-color: var(--focus-2);
}

.input:active {
	border-color: var(--border-3);
}

.input.error {
	color: var(--error);
}

.input.active.error ~ .label,
.input:active.error ~ .label {
	color: var(--error);
}

.input:active.error {
	border-color: var(--error);
}

.input:disabled {
	border-color: var(--border-disabled);
	background-color: var(--bg-disabled);
	color: var(--text-disabled);
}

.input:disabled ~ label {
	color: var(--text-disabled);
}

.select_options {
	position: absolute;
	top: 39px;
	width: 100%;
	max-height: 200px;
	overflow-y: auto;
	overflow-x: hidden;

	box-shadow: var(--box-shadow-md);
	background-color: var(--white);
}

.select_options--option {
	min-width: 300px;
	min-height: 40px;
	padding-inline: var(--spc-3xs);
	gap: var(--spc-3xs);
	cursor: pointer;
}

.select_options--option:hover,
.select_options--option:focus {
	background-color: var(--bg-5);
}

.select_options--option-selected {
	position: absolute;
	top: 10px;
	left: calc(var(--spc-3xs));
	z-index: 1;
	line-height: 20px !important;
}

.icon--prepend ~ .select_options--option-selected {
	left: calc(var(--spc-3xs) + 26px);
}

.input--select {
	cursor: pointer;
}

.no_icon_prepend .input {
	padding-left: var(--spc-3xs);
}

.no_icon_prepend .label {
	left: var(--spc-3xs);
}

.input--checkbox[type="checkbox"] {
	display: grid;
	place-content: center;

	appearance: none;
	background-color: var(--white);
	margin-right: 12px;
	width: 24px;
	height: 24px;
	padding: 0;

	@media (--desktop) {
		width: 16px;
		height: 16px;
	}
}

.input--checkbox[type="checkbox"]::before {
	content: "";
	width: 24px;
	height: 24px;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAIVBMVEVHcEwAABsGAh8FAR8FAh8JACAGAB4HACAGAyAHAx4GAh/gkttmAAAACnRSTlMACda+5xcqJVdNuiS4EgAAAERJREFUGNNjYKAJYLRE4ggvEkBIWLkhSSxJQJJwhzCaAhASjFqqCAmGoEUBCB2sWupIRgWtWlIA57BqIdnBEFxACw8CAA7+DE5JmmzQAAAAAElFTkSuQmCC');
	background-size: cover;
	transform: scale(0);
	transform-origin: bottom left;
	transition: 120ms transform ease-in-out;

	@media (--desktop) {
		width: 16px;
		height: 16px;
	}
}

.input--checkbox[type="checkbox"]:checked::before {
	transform: scale(1);
}

.input--checkbox[type="checkbox"]:disabled {
	border-color: var(--border-disabled);
	background-color: var(--bg-disabled);
}

.group--input.type_checkbox {
	align-items: center !important;
	min-height: 40px;
}

.submit {
	position: relative;
}

.submit > button {
	min-width: 100%;
	background-color: var(--brand-3);
}

.form.sending .input {
	background-color: var(--bg-disabled);
	border-color: var(--border-disabled);
	cursor: none;
	pointer-events: none;
}

.form.sending .input,
.form.sending .label,
.form.sending span,
.form.sending svg {
	color: var(--text-disabled);
}

/*.form.sending ~ .submit > button:hover {*/
/*	background-color: var(--brand-3);*/
/*	cursor: none;*/
/*	pointer-events: none;*/
/*}*/

.form.sending button:after {
	content: "";
	background-color: var(--bg-disabled);
	position: absolute;
	inset: 0;
	z-index: 2;
	opacity: 0.5;
}

.submit.sending > button {
	color: var(--text-button-2-disabled);
	background-color: var(--bg-button-2-disabled);
}

.submit.sending > button:after {
	opacity: 0;
	background-color: transparent;
	z-index: 0;
}

.submit.sending > button > span {
	display: flex;
	align-items: center;
	gap: var(--spc-3xs);
}

.submit.sending > button > span > svg {
	animation: rotation 1s linear infinite;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
	input, label, select, textarea {
		font-size: 16px !important
	}
}

input[type="date"] {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: textfield;
}