:root {
	font-family: var(--font-1);
	--container-max-width: var(--mobile-max-width);
	--container-margin: var(--spc-2xs);
}

.container {
	max-width: var(--container-max-width);
	margin-inline: var(--container-margin);

	@media (--tablet) {
		--container-max-width: var(--tablet-max-width);
		--container-margin: var(--spc-lg);
	}

	@media (--desktop) {
		--container-max-width: var(--desktop-max-width);
		--container-margin: auto;
	}

	@media (--big-screen) {
		--container-max-width: var(--big-screen-max-width);
	}
}

.container__override {
	margin-inline: calc(-1 * var(--container-margin));
	@media (--desktop) {
		/* 0.5 * (100% - --desktop-max-width[80%]) in vw */
		/* is the same for big screen */
		margin-inline: -10vw;
	}
}

.color__success {
	color: var(--success);
}

.color__error {
	color: var(--error);
}

.color__warning {
	color: var(--warning);
}

.color__disabled {
	color: var(--text-disabled);
}

.debug {
	border: 4px dashed var(--error);
}

/* Vertical Spacing */
.verticalSpacingSmall {
	padding-top: var(--spc-sm);
	padding-bottom: var(--spc-md);

	@media (--tablet) {
		padding-top: var(--spc-md);
		padding-bottom: var(--spc-lg);
	}

	@media (--desktop) {
		padding-top: var(--spc-xl);
		padding-bottom: var(--spc-2xl);
	}

	@media (--big-screen) {
		padding-top: var(--spc-2xl);
		padding-bottom: var(--spc-2xl);
	}
}

.verticalSpacingMedium {
	padding-top: var(--spc-md);
	padding-bottom: var(--spc-xl);

	@media (--tablet) {
		padding-top: var(--spc-xl);
		padding-bottom: var(--spc-2xl);
	}

	@media (--desktop) {
		padding-top: var(--spc-3xl);
		padding-bottom: var(--spc-3xl);
	}

	@media (--big-screen) {
		padding-top: var(--spc-4xl);
		padding-bottom: var(--spc-4xl);
	}
}

.verticalSpacingLarge {
	padding-top: var(--spc-2xl);
	padding-bottom: var(--spc-2xl);

	@media (--tablet) {
		padding-top: var(--spc-3xl);
		padding-bottom: var(--spc-3xl);
	}

	@media (--desktop) {
		padding-top: var(--spc-4xl);
		padding-bottom: var(--spc-4xl);
	}

	@media (--big-screen) {
		padding-top: var(--spc-5xl);
		padding-bottom: var(--spc-5xl);
	}
}

.grid {
	gap: var(--spc-sm) var(--spc-2xs);
}

@media (min-width: 700px) {
	.grid {
		gap: var(--spc-sm) var(--spc-md);
	}
}

@media (min-width: 1024px) {
	.grid {
		grid-column-gap: var(--spc-sm);
	}
}

@media (min-width: 1680px) {
	.grid {
		gap: var(--spc-lg);
	}
}