.display,
.heading-2xl,
.heading-xl,
.heading-lg,
.heading-md,
.heading-sm,
.heading-xs {
	font-family: var(--font-2);
	font-style: normal;
}

.display {
	font-size: 72px;
	font-weight: 700;
	line-height: 100%;
	@media (--tablet) {
		font-size: 80px;
	}
	@media (--desktop) {
		font-size: 96px;
	}
	@media (--big-screen) {
		font-size: 112px;
	}
}

.heading-2xl,
.heading-xl,
.heading-lg,
.heading-md,
.heading-sm,
.heading-xs {
	font-family: var(--font-2);
	font-weight: 600;
}

.heading-2xl {
	font-size: 48px;
	line-height: 110%;
	@media (--tablet) {
		font-size: 56px;
	}
	@media (--desktop) {
		font-size: 64px;
	}
	@media (--big-screen) {
		font-size: 72px;
	}
}

.heading-xl {
	font-size: 40px;
	line-height: 110%;
	@media (--tablet) {
		font-size: 44px;
	}
	@media (--desktop) {
		font-size: 48px;
	}
	@media (--big-screen) {
		font-size: 56px;
	}
}

.heading-lg {
	font-size: 32px;
	line-height: 120%;
	@media (--tablet) {
		font-size: 36px;
	}
	@media (--desktop) {
		font-size: 40px;
	}
	@media (--big-screen) {
		font-size: 44px;
	}
}

.heading-md {
	font-size: 24px;
	line-height: 130%;
	@media (--tablet) {
		font-size: 28px;
	}
	@media (--desktop) {
		font-size: 32px;
	}
	@media (--big-screen) {
		font-size: 34px;
	}
}

.heading-sm {
	font-size: 20px;
	line-height: 130%;
	@media (--tablet) {
		font-size: 22px;
	}
	@media (--desktop) {
		font-size: 24px;
	}
	@media (--big-screen) {
		font-size: 28px;
	}
}

.heading-xs {
	font-size: 16px;
	line-height: 140%;
	@media (--tablet) {
		font-size: 18px;
	}
	@media (--desktop) {
		font-size: 20px;
	}
	@media (--big-screen) {
		font-size: 22px;
	}
}

/* Body */
.body-lead-lg,
.body-lead-md,
.body,
.body-inline-lg,
.body-inline-md,
.body-inline-sm {
	font-family: var(--font-1);
	font-style: normal;
}

.body-inline-lg,
.body-inline-md,
.body-inline-sm {
	line-height: 130%;
}

.body-lead-lg {
	font-size: 20px;
	line-height: 133%;

	@media (--desktop) {
		font-size: 22px;
	}
	@media (--big-screen) {
		font-size: 24px;
	}
}

.body-lead-md {
	font-size: 18px;
	line-height: 150%;

	@media (--desktop) {
		font-size: 20px;
	}
}

.body,
.body-bold {
	font-size: 14px;
	line-height: 161.8%;
	@media (--tablet) {
		font-size: 15px;
	}
	@media (--desktop) {
		font-size: 16px;
	}
	@media (--big-screen) {
		font-size: 18px;
	}
}

.body-bold {
	font-weight: 700;
}

.body-inline-lg {
	font-size: 15px;

	@media (--desktop) {
		font-size: 16px;
	}
}

.body-inline-md {
	font-size: 13px;

	@media (--desktop) {
		font-size: 14px;
	}
}

.body-inline-sm {
	font-size: 12px;
}

/* Components */
.label-button-md,
.label-button-sm,
.label-button-text,
.menu-m,
.menu-s,
.quote,
.breadcrumb {
	font-family: var(--font-1);
	font-style: normal;
}

.label-button-md,
.label-button-sm,
.label-button-text {
	font-weight: 700;
}

.menu-m,
.menu-s,
.quote,
.breadcrumb {
	font-weight: 500;
}

.label-button-md,
.label-button-sm,
.label-button-text,
.menu-m,
.menu-s,
.breadcrumb {
	line-height: 100%;
}

.label-button-md {
	font-size: 16px;
}

.label-button-sm {
	font-size: 14px;
}

.label-button-text {
	font-size: 14px;
	@media (--tablet) {
		font-size: 15px;
	}
	@media (--desktop) {
		font-size: 16px;
	}
}

.menu-m {
	font-size: 16px;
	@media (--desktop) {
		font-size: 14px;
	}
	@media (--big-screen) {
		font-size: 16px;
	}
}

.menu-s {
	font-size: 14px;
	@media (--desktop) {
		font-size: 12px;
	}
	@media (--big-screen) {
		font-size: 14px;
	}
}

.quote {
	font-size: 24px;
	font-style: italic;
	line-height: 161.8%;
	@media (--tablet) {
		font-size: 32px;
	}
	@media (--desktop) {
		font-size: 40px;
	}
	@media (--big-screen) {
		font-size: 48px;
	}
}

.breadcrumb {
	font-size: 12px;
	@media (--desktop) {
		font-size: 16px;
	}
}

.text-font-1 {
	font-family: var(--font-1);
}

.text-font-2 {
	font-family: var(--font-2);
}

.text-font-3 {
	font-family: var(--font-3);
}
