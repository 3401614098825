@media only screen and (max-width: 600px) {
	#onetrust-banner-sdk .banner-header {
		display: none!important;
	}

	#onetrust-banner-sdk.otChoicesBnr {
		top: 20px!important;
	}

	.banner-actions-container{
		flex-direction: column;
		display: flex;
	}
	.banner-actions-container #onetrust-reject-all-handler{
		order:2;
	}
	.banner-actions-container .ot-bnr-save-handler{
		order:3;
	}
}
