/*

  Theme: Second theme

  -----------------------------------
  🚫 DO NOT EDIT THIS FILE DIRECTLY 🚫
  -----------------------------------

  To generate this file:
  1 - Edit `src/themes/second-theme` files
  2 - Run `yarn build:themes`

*/

[data-theme="second-theme"][data-subtheme="default"],
[data-theme="second-theme"] [data-subtheme="default"] {
	/* fontFamily */
	--font-1: "Aeonik", serif;
	--font-2: "Aeonik", serif;
	/* containers */
	--mobile-max-width: 100%;
	--tablet-max-width: 100%;
	--desktop-max-width: 80%;
	--big-screen-max-width: 80%;
	/* brandColors */
	--brand-1: #ff5700;
	--brand-2: #d6d1c4;
	--brand-3: #1a1f6c;
	/* background */
	--bg-ui: #ffffffff;
	--bg-1: #ffffffff;
	--bg-2: #d6d1c4ff;
	--bg-3: #1a1f6cff;
	--bg-4: #ff5700ff;
	--bg-5: #efede8ff;
	--bg-disabled: #eeeeeeff;
	/* backgroundButton */
	--bg-button-1: #ff5700ff;
	--bg-button-1-hover: #f89d4fff;
	--bg-button-1-active: #f45300ff;
	--bg-button-1-focus: #ff5700ff;
	--bg-button-1-disabled: #eeeeeeff;
	--bg-button-2: #1a1f6cff;
	--bg-button-2-hover: #5f639cff;
	--bg-button-2-active: #070d61ff;
	--bg-button-2-focus: #1a1f6cff;
	--bg-button-2-disabled: #eeeeeeff;
	--bg-button-3: #1a1f6c00;
	--bg-button-3-hover: #1a1f6c1a;
	--bg-button-3-active: #1a1f6c00;
	--bg-button-3-focus: #1a1f6c00;
	--bg-button-3-disabled: #eeeeee00;
	--bg-link-button-4: #ffffff00;
	--bg-link-button-4-hover: #1a1f6c1a;
	--bg-link-button-4-active: #ffffff00;
	--bg-link-button-4-focus: #ffffff00;
	--bg-link-button-4-disabled: #eeeeee00;
	/* borderButton */
	--border-button-1: #f89d4f00;
	--border-button-1-hover: #f89d4f00;
	--border-button-1-active: #f89d4f00;
	--border-button-1-focus: #313cd8ff;
	--border-button-1-disabled: #99999900;
	--border-button-2: #1a1f6c00;
	--border-button-2-hover: #1a1f6c00;
	--border-button-2-active: #1a1f6c00;
	--border-button-2-focus: #313cd8ff;
	--border-button-2-disabled: #99999900;
	--border-button-3: #1a1f6cff;
	--border-button-3-hover: #1a1f6cff;
	--border-button-3-active: #070d61ff;
	--border-button-3-focus: #313cd8ff;
	--border-button-3-disabled: #bbbbbbff;
	--border-button-4: #ffffff00;
	--border-button-4-hover: #ffffff00;
	--border-button-4-active: #ffffff00;
	--border-button-4-focus: #313cd8ff;
	--border-button-4-disabled: #99999900;
	/* textButton */
	--text-button-1: #ffffffff;
	--text-button-1-hover: #ffffffff;
	--text-button-1-active: #ffffffff;
	--text-button-1-focus: #ffffffff;
	--text-button-1-disabled: #999999ff;
	--text-button-2: #ffffffff;
	--text-button-2-hover: #ffffffff;
	--text-button-2-active: #ffffffff;
	--text-button-2-focus: #ffffffff;
	--text-button-2-disabled: #999999ff;
	--text-button-3: #1a1f6cff;
	--text-button-3-hover: #5f639cff;
	--text-button-3-active: #070d61ff;
	--text-button-3-focus: #1a1f6cff;
	--text-button-3-disabled: #999999ff;
	--text-button-4: #1a1f6cff;
	--text-button-4-hover: #5f639cff;
	--text-button-4-active: #070d61ff;
	--text-button-4-focus: #1a1f6cff;
	--text-button-4-disabled: #999999ff;
	--text-button-5: #ff5700;
	--text-button-5-hover: #f89d4f;
	--text-button-5-active: #ff5700;
	--text-button-5-focus: #ff5700;
	--text-button-5-disabled: #b4b4b4;
	/* textLink */
	--text-link-1: #ff5700ff;
	--text-link-1-hover: #f89d4fff;
	--text-link-1-active: #ff5700ff;
	--text-link-1-focus: #ff5700ff;
	--text-link-1-disabled: #b4b4b4ff;
	--text-link-menu: #1a1f6cff;
	--text-link-menu-hover: #5f639cff;
	--text-link-menu-active: #070d61ff;
	--text-link-menu-focus: #1a1f6cff;
	--text-link-menu-disabled: #b4b4b4ff;
	/* text */
	--text-1: #000000ff;
	--text-2: #1a1f6cff;
	--text-3: #747168ff;
	--text-4: #ffffffff;
	--text-disabled: #b4b4b4ff;
	/* icon */
	--icon-1: #06021fff;
	--icon-2: #1a1f6cff;
	--icon-3: #ff5700ff;
	--icon-4: #ff5700ff;
	--icon-disabled: #aaaaaaff;
	/* border */
	--border-1: #ffffffff;
	--border-2: #d6d6d6ff;
	--border-3: #1a1f6cff;
	--border-4: #ff5700ff;
	--border-disabled: #bbbbbb;
	/* veilColors */
	--overlay-1: #ffffff54;
	--overlay-2: #ffffffcc;
	--overlay-3: #00000054;
	--overlay-4: #000000cc;
	/* gradients */
	--gradient-1: linear-gradient(180deg, #efede8 0%, #ffffff 100%);
	--gradient-2: linear-gradient(180deg, #d9d9d9 0%, #1a1f6c 100%);
	--gradient-3: linear-gradient(180deg, #ffffff 0%, #f6f5f1 100%);
	/* support */
	--success: #00ad26ff;
	--error: #ef0c35ff;
	--warning: #ff9900ff;
	--focus-1: #313cd8ff;
	--focus-2: #50abffff;
	--white: #ffffff;
	/* spacing */
	--spc-4xs: 4px;
	--spc-3xs: 8px;
	--spc-2xs: 16px;
	--spc-xs: 24px;
	--spc-sm: 32px;
	--spc-md: 40px;
	--spc-lg: 48px;
	--spc-xl: 56px;
	--spc-2xl: 64px;
	--spc-3xl: 80px;
	--spc-4xl: 96px;
	--spc-5xl: 128px;
	/* boxShadow */
	--box-shadow-sm: 0px 2px 8px 0px rgba(26, 31, 108, 0.12);
	--box-shadow-md: 0px 5px 26px 0px rgba(26, 31, 108, 0.12);
	--box-shadow-lg: 0px 20px 34px 0px rgba(26, 31, 108, 0.1);
	--box-shadow-xl: 0px 18px 26px 0px rgba(26, 31, 108, 0.2);
	/* dropShadow */
	--drop-shadow-sm: drop-shadow(0px 4px 18px rgba(var(--shadow-1), 0.08));
	--drop-shadow-md: drop-shadow(0px 5px 26px rgba(var(--shadow-1), 0.08));
	--drop-shadow-lg: drop-shadow(0px 20px 34px rgba(var(--shadow-1), 0.1));
	--drop-shadow-xl: drop-shadow(14px 47px 132px rgba(var(--shadow-1), 0.2));
	/* opacity */
	--opacity-1: 0.08;
	--opacity-2: 0.3;
	--opacity-3: 0.4;
	--opacity-4: 0.56;
	--opacity-5: 0.64;
	--opacity-6: 0.72;
	/* radii */
	--radii-none: 0px;
	--radii-xs: 4px;
	--radii-sm: 8px;
	--radii-md: 16px;
	--radii-lg: 24px;
	--radii-xl: 32px;
	--radii-full: 9999px;
	/* sizes */
	--size-2xs: 8px;
	--size-xs: 16px;
	--size-sm: 24px;
	--size-md: 32px;
	--size-lg: 48px;
	--size-xl: 56px;
	--size-2xl: 64px;
	--size-3xl: 88px;
	/* borderWidth */
	--border-width-none: 0px;
	--border-width-xs: 1px;
	--border-width-sm: 2px;
	--border-width-md: 4px;
	--border-width-lg: 8px;
	/* minHeigh */
	--min-height-hero-1: 400px;
	--min-height-hero-2: 600px;
	--min-height-hero-3: 800px;
	--min-height-hero-4: 1000px;
	--min-height-button-1: 48px;
	/* animations */
	--fade-in: fadein both 1.5s cubic-bezier(0.61, 0.43, 0.15, 1);
	--show-up: show-up 2s ease-in-out;
	--delay-fade-in: fadein both 1.5s cubic-bezier(0.61, 0.43, 0.15, 1) 0.1s;
	--menu-hover: menu-hover both 0.3s cubic-bezier(0.61, 0.43, 0.15, 1);
	--in-expo: cubic-bezier(0.61, 0.43, 0.15, 1);
	/* timing */
	--duration-xs: 0.1s;
	--duration-sm: 0.2s;
	--duration-md: 0.3s;
	--duration-lg: 0.5s;
	--duration-xl: 0.75s;
	/* easing */
	--easing-xs: var(--ease-in-out-quad);
	--easing-sm: var(--ease-in-out-cubic);
	--easing-md: var(--ease-in-out-quart);
	--easing-lg: var(--ease-in-out-quint);
	--easing-xl: var(--ease-in-out-expo);
	/* easingFunctions */
	--ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	--ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
	--ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
	--ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
	--ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
}
[data-theme="second-theme"][data-subtheme="default-alt"],
[data-theme="second-theme"] [data-subtheme="default-alt"] {
	/* fontFamily */
	--font-1: "Aeonik", serif;
	--font-2: "Aeonik", serif;
	/* containers */
	--mobile-max-width: 100%;
	--tablet-max-width: 100%;
	--desktop-max-width: 80%;
	--big-screen-max-width: 80%;
	/* brandColors */
	--brand-1: #ff5700;
	--brand-2: #d6d1c4;
	--brand-3: #1a1f6c;
	/* background */
	--bg-ui: #ffffffff;
	--bg-1: #ffffffff;
	--bg-2: #d6d1c4ff;
	--bg-3: #1a1f6cff;
	--bg-4: #ff5700ff;
	--bg-5: #efede8ff;
	--bg-disabled: #eeeeeeff;
	/* backgroundButton */
	--bg-button-1: #ff5700ff;
	--bg-button-1-hover: #f89d4fff;
	--bg-button-1-active: #f45300ff;
	--bg-button-1-focus: #ff5700ff;
	--bg-button-1-disabled: #eeeeeeff;
	--bg-button-2: #1a1f6cff;
	--bg-button-2-hover: #5f639cff;
	--bg-button-2-active: #070d61ff;
	--bg-button-2-focus: #1a1f6cff;
	--bg-button-2-disabled: #eeeeeeff;
	--bg-button-3: #1a1f6c00;
	--bg-button-3-hover: #1a1f6c1a;
	--bg-button-3-active: #1a1f6c00;
	--bg-button-3-focus: #1a1f6c00;
	--bg-button-3-disabled: #eeeeee00;
	--bg-link-button-4: #ffffff00;
	--bg-link-button-4-hover: #1a1f6c1a;
	--bg-link-button-4-active: #ffffff00;
	--bg-link-button-4-focus: #ffffff00;
	--bg-link-button-4-disabled: #eeeeee00;
	/* borderButton */
	--border-button-1: #f89d4f00;
	--border-button-1-hover: #f89d4f00;
	--border-button-1-active: #f89d4f00;
	--border-button-1-focus: #313cd8ff;
	--border-button-1-disabled: #99999900;
	--border-button-2: #1a1f6c00;
	--border-button-2-hover: #1a1f6c00;
	--border-button-2-active: #1a1f6c00;
	--border-button-2-focus: #313cd8ff;
	--border-button-2-disabled: #99999900;
	--border-button-3: #1a1f6cff;
	--border-button-3-hover: #1a1f6cff;
	--border-button-3-active: #070d61ff;
	--border-button-3-focus: #313cd8ff;
	--border-button-3-disabled: #bbbbbbff;
	--border-button-4: #ffffff00;
	--border-button-4-hover: #ffffff00;
	--border-button-4-active: #ffffff00;
	--border-button-4-focus: #313cd8ff;
	--border-button-4-disabled: #99999900;
	/* textButton */
	--text-button-1: #ffffffff;
	--text-button-1-hover: #ffffffff;
	--text-button-1-active: #ffffffff;
	--text-button-1-focus: #ffffffff;
	--text-button-1-disabled: #999999ff;
	--text-button-2: #ffffffff;
	--text-button-2-hover: #ffffffff;
	--text-button-2-active: #ffffffff;
	--text-button-2-focus: #ffffffff;
	--text-button-2-disabled: #999999ff;
	--text-button-3: #1a1f6cff;
	--text-button-3-hover: #5f639cff;
	--text-button-3-active: #070d61ff;
	--text-button-3-focus: #1a1f6cff;
	--text-button-3-disabled: #999999ff;
	--text-button-4: #1a1f6cff;
	--text-button-4-hover: #5f639cff;
	--text-button-4-active: #070d61ff;
	--text-button-4-focus: #1a1f6cff;
	--text-button-4-disabled: #999999ff;
	--text-button-5: #ff5700;
	--text-button-5-hover: #f89d4f;
	--text-button-5-active: #ff5700;
	--text-button-5-focus: #ff5700;
	--text-button-5-disabled: #b4b4b4;
	/* textLink */
	--text-link-1: #ff5700ff;
	--text-link-1-hover: #f89d4fff;
	--text-link-1-active: #ff5700ff;
	--text-link-1-focus: #ff5700ff;
	--text-link-1-disabled: #b4b4b4ff;
	--text-link-menu: #1a1f6cff;
	--text-link-menu-hover: #5f639cff;
	--text-link-menu-active: #070d61ff;
	--text-link-menu-focus: #1a1f6cff;
	--text-link-menu-disabled: #b4b4b4ff;
	/* text */
	--text-1: #000000ff;
	--text-2: #1a1f6cff;
	--text-3: #747168ff;
	--text-4: #ffffffff;
	--text-disabled: #b4b4b4ff;
	/* icon */
	--icon-1: #06021fff;
	--icon-2: #1a1f6cff;
	--icon-3: #ff5700ff;
	--icon-4: #ff5700ff;
	--icon-disabled: #aaaaaaff;
	/* border */
	--border-1: #ffffffff;
	--border-2: #d6d6d6ff;
	--border-3: #1a1f6cff;
	--border-4: #ff5700ff;
	--border-disabled: #bbbbbb;
	/* veilColors */
	--overlay-1: #ffffff54;
	--overlay-2: #ffffffcc;
	--overlay-3: #00000054;
	--overlay-4: #000000cc;
	/* gradients */
	--gradient-1: linear-gradient(180deg, #efede8 0%, #ffffff 100%);
	--gradient-2: linear-gradient(180deg, #d9d9d9 0%, #1a1f6c 100%);
	--gradient-3: linear-gradient(180deg, #ffffff 0%, #f6f5f1 100%);
	/* support */
	--success: #00ad26ff;
	--error: #ef0c35ff;
	--warning: #ff9900ff;
	--focus-1: #313cd8ff;
	--focus-2: #50abffff;
	--white: #ffffff;
	/* spacing */
	--spc-4xs: 4px;
	--spc-3xs: 8px;
	--spc-2xs: 16px;
	--spc-xs: 24px;
	--spc-sm: 32px;
	--spc-md: 40px;
	--spc-lg: 48px;
	--spc-xl: 56px;
	--spc-2xl: 64px;
	--spc-3xl: 80px;
	--spc-4xl: 96px;
	--spc-5xl: 128px;
	/* boxShadow */
	--box-shadow-sm: 0px 2px 8px 0px rgba(26, 31, 108, 0.12);
	--box-shadow-md: 0px 5px 26px 0px rgba(26, 31, 108, 0.12);
	--box-shadow-lg: 0px 20px 34px 0px rgba(26, 31, 108, 0.1);
	--box-shadow-xl: 0px 18px 26px 0px rgba(26, 31, 108, 0.2);
	/* dropShadow */
	--drop-shadow-sm: drop-shadow(0px 4px 18px rgba(var(--shadow-1), 0.08));
	--drop-shadow-md: drop-shadow(0px 5px 26px rgba(var(--shadow-1), 0.08));
	--drop-shadow-lg: drop-shadow(0px 20px 34px rgba(var(--shadow-1), 0.1));
	--drop-shadow-xl: drop-shadow(14px 47px 132px rgba(var(--shadow-1), 0.2));
	/* opacity */
	--opacity-1: 0.08;
	--opacity-2: 0.3;
	--opacity-3: 0.4;
	--opacity-4: 0.56;
	--opacity-5: 0.64;
	--opacity-6: 0.72;
	/* radii */
	--radii-none: 0px;
	--radii-xs: 4px;
	--radii-sm: 8px;
	--radii-md: 16px;
	--radii-lg: 24px;
	--radii-xl: 32px;
	--radii-full: 9999px;
	/* sizes */
	--size-2xs: 8px;
	--size-xs: 16px;
	--size-sm: 24px;
	--size-md: 32px;
	--size-lg: 48px;
	--size-xl: 56px;
	--size-2xl: 64px;
	--size-3xl: 88px;
	/* borderWidth */
	--border-width-none: 0px;
	--border-width-xs: 1px;
	--border-width-sm: 2px;
	--border-width-md: 4px;
	--border-width-lg: 8px;
	/* minHeigh */
	--min-height-hero-1: 400px;
	--min-height-hero-2: 600px;
	--min-height-hero-3: 800px;
	--min-height-hero-4: 1000px;
	--min-height-button-1: 48px;
	/* animations */
	--fade-in: fadein both 1.5s cubic-bezier(0.61, 0.43, 0.15, 1);
	--show-up: show-up 2s ease-in-out;
	--delay-fade-in: fadein both 1.5s cubic-bezier(0.61, 0.43, 0.15, 1) 0.1s;
	--menu-hover: menu-hover both 0.3s cubic-bezier(0.61, 0.43, 0.15, 1);
	--in-expo: cubic-bezier(0.61, 0.43, 0.15, 1);
	/* timing */
	--duration-xs: 0.1s;
	--duration-sm: 0.2s;
	--duration-md: 0.3s;
	--duration-lg: 0.5s;
	--duration-xl: 0.75s;
	/* easing */
	--easing-xs: var(--ease-in-out-quad);
	--easing-sm: var(--ease-in-out-cubic);
	--easing-md: var(--ease-in-out-quart);
	--easing-lg: var(--ease-in-out-quint);
	--easing-xl: var(--ease-in-out-expo);
	/* easingFunctions */
	--ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	--ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
	--ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
	--ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
	--ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
	/* background */
	--bg-1: #efede8;
	--bg-5: #ffffff;
}
[data-theme="second-theme"][data-subtheme="inverse"],
[data-theme="second-theme"] [data-subtheme="inverse"] {
	/* background */
	--bg-ui: #06021fff;
	--bg-1: #1a1f6cff;
	--bg-2: #5055a7ff;
	--bg-3: #9b9fe1ff;
	--bg-4: #ff5700ff;
	--bg-5: #515c6cff;
	--bg-disabled: #5055a7ff;
	/* backgroundButton */
	--bg-button-1-disabled: #515c6cff;
	--bg-button-2: #d6d1c4ff;
	--bg-button-2-hover: #f0ebddff;
	--bg-button-2-active: #d6d1c4ff;
	--bg-button-2-focus: #d6d1c4ff;
	--bg-button-2-disabled: #515c6cff;
	--bg-button-3: #f0ebdd00;
	--bg-button-3-hover: #f0ebdd1a;
	--bg-button-3-active: #f0ebdd00;
	--bg-button-3-focus: #f0ebdd00;
	--bg-button-3-disabled: #717c8c00;
	--bg-link-button-4: #d9d9d900;
	--bg-link-button-4-hover: #f0ebdd1a;
	--bg-link-button-4-active: #d9d9d900;
	--bg-link-button-4-focus: #d9d9d900;
	--bg-link-button-4-disabled: #515c6c00;
	/* borderButton */
	--border-button-1-focus: #4650f3ff;
	--border-button-1-disabled: #515c6c00;
	--border-button-2: #d6d1c400;
	--border-button-2-hover: #d6d1c400;
	--border-button-2-active: #d6d1c400;
	--border-button-2-focus: #4650f3ff;
	--border-button-2-disabled: #515c6c00;
	--border-button-3: #d6d1c4ff;
	--border-button-3-hover: #d6d1c4ff;
	--border-button-3-active: #d6d1c4ff;
	--border-button-3-focus: #4650f3ff;
	--border-button-3-disabled: #515c6cff;
	--border-button-4: #d9d9d900;
	--border-button-4-hover: #d9d9d900;
	--border-button-4-active: #d9d9d900;
	--border-button-4-focus: #4650f3ff;
	--border-button-4-disabled: #515c6c00;
	/* textButton */
	--text-button-1-disabled: #878d96ff;
	--text-button-2: #1a1f6cff;
	--text-button-2-hover: #5f639cff;
	--text-button-2-active: #1a1f6cff;
	--text-button-2-focus: #1a1f6cff;
	--text-button-2-disabled: #878d96ff;
	--text-button-3: #d6d1c4ff;
	--text-button-3-hover: #d6d1c4ff;
	--text-button-3-active: #d6d1c4ff;
	--text-button-3-focus: #d6d1c4ff;
	--text-button-3-disabled: #515c6cff;
	--text-button-4: #d6d1c4ff;
	--text-button-4-hover: #d6d1c4ff;
	--text-button-4-active: #d6d1c4ff;
	--text-button-4-focus: #d6d1c4ff;
	--text-button-4-disabled: #878d96ff;
	--text-button-5: #ff5700;
	--text-button-5-hover: #f89d4f;
	--text-button-5-active: #ff5700;
	--text-button-5-focus: #ff5700;
	--text-button-5-disabled: #b4b4b4;
	/* textLink */
	--text-link-1: #d6d1c4ff;
	--text-link-1-hover: #f0ebddff;
	--text-link-1-active: #d6d1c4ff;
	--text-link-1-focus: #d6d1c4ff;
	--text-link-1-disabled: #838e9cff;
	--text-link-menu: #d6d1c4ff;
	--text-link-menu-hover: #d6d1c4ff;
	--text-link-menu-active: #d6d1c4ff;
	--text-link-menu-focus: #d6d1c4ff;
	--text-link-menu-disabled: #838e9cff;
	/* text */
	--text-1: #ffffffff;
	--text-2: #d6d1c4ff;
	--text-3: #efede8ff;
	--text-disabled: #7a8493ff;
	/* icon */
	--icon-1: #ffffffff;
	--icon-2: #ff5700ff;
	--icon-3: #d6d1c4ff;
	--icon-disabled: #838e9cff;
	/* border */
	--border-1: #1a1f6cff;
	--border-2: #464985ff;
	--border-3: #ffffffff;
	--border-disabled: #515c6cff;
	/* veilColors */
	--overlay-1: #00000054;
	--overlay-2: #000000cc;
	--overlay-3: #ffffff54;
	--overlay-4: #ffffffcc;
	/* support */
	--success: #5efb9dff;
	--focus-1: #4650f3ff;
	--focus-2: #3ee8ffff;
}
