.screen-read-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border-width: 0;
}

.not-screen-read-only {
	position: static;
	width: auto;
	height: auto;
	padding: 0;
	margin: 0;
	overflow: visible;
	clip: auto;
	white-space: normal;
}

img {
	width: 100%;
}

.noScroll {
	overflow-y: hidden;
}

/* Evita que el "pixel de facebook" ocupe más de 1x1 */
body > img {
	width: inherit;
	height: inherit;
}
